export const kpis = {
  'uk-tax-paid': {
    name: 'Pay a fair share of UK tax',
    description:
      '£7bn tax revenues are lost every year as businesses shift profits to low tax jurisdictions.  Enough to put over £200 back in the pocket of every UK taxpayer, every year.',
  },
  'gender-pay-gap': {
    name: 'End the Gender Pay Gap',
    description:
      '78% businesses have a gender pay gap.  Women will earn £263k less than men in their lifetime. That’s the price of a house!',
  },
  'real-living-wage': {
    name: 'Pay the real Living Wage',
    description:
      'Almost 1 in 5 workers are paid less than the RLW, less than they need to live on. Of these, a quarter had to skip meals for financial reasons in 2020.',
  },
  'co2-emissions': {
    name: 'Zero Carbon Emissions',
    description:
      '75% businesses are concerned about climate risks, but only 1 in 10 is prioritising their measurement & reporting. Must do better!',
  },
  'pay-suppliers-promptly': {
    name: 'Pay Suppliers Promptly',
    description:
      'Nearly 17m people are employed by SMEs in the UK. These companies need to be paid promptly by their business customers or risk going bust.',
  },
  'ceo-pay-ratio': {
    name: 'Workers pay in line with bosses',
    description:
      'CEOs can earn £m’s… for treating customers, employees & the environment like crap. Is that OK?',
  },
};

export const getKpiName = key => kpis[key].name;

export const getKpiDescription = key => kpis[key].description;
