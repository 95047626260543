import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { getKpiName, getKpiDescription } from '../utils';
import {
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles/utilities';
import { Button } from './ui';

const StyledPreferencesForm = styled.form`
  margin: 20px 0;
`;

const StyledPreferencesFormLabel = styled.label`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
`;

const StyledPreferencesFormText = styled.span`
  color: ${standardColours.black};
  font-weight: ${fontWeights.semibold};
  ${fontSize(15)};

  ${({ alt }) => {
    if (alt) {
      return `
        color: ${brandColours.secondary};
        font-size: 11px;
      `;
    }
  }}
`;

const StyledPreferencesFormButton = styled.button`
  margin-left: 12px;
  padding: 0;
  height: 20px;
  width: 20px;
  color: ${brandColours.secondary};
  text-align: center;
  background: none;
  border: 1px solid ${brandColours.secondary};
  border-radius: 50%;

  &:before {
    content: '?';
  }

  span {
    ${visuallyHidden()};
  }

  ${({ pulse }) => {
    if (!pulse) {
      return `
        animation: pulse 2s infinite;
      `;
    }
  }}

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${brandColours.secondary};
    }

    80% {
      box-shadow: 0 0 0 6px ${standardColours.transparent};
    }

    100% {
      box-shadow: 0 0 0 0 ${standardColours.transparent};
    }
  }
`;

const StyledPreferencesFormInput = styled.input`
  -webkit-appearance: none;
  margin: 14px 0;
  height: 2px;
  width: 100%;
  background-color: ${brandColours.primary};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background-color: ${brandColours.primary};
    border-radius: 50%;
  }
`;

const StyledPreferencesFormPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 20px 40px 20px 20px;
  width: 240px;
  background-color: ${standardColours.white};
  border: 1px solid ${brandColours.tertiary};
  border-radius: 22px;
  transform: translate(-50%, -50%);
`;

const StyledPreferencesFormPopupButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  background: none;
  border: none;

  &:before {
    content: '✕';
    color: ${brandColours.primary};
    ${fontSize(22)};
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledPreferencesFormPopupText = styled.p`
  color: ${brandColours.secondary};
  font-weight: ${fontWeights.semibold};
  ${fontSize(16)};
  line-height: 1.7;
`;

const PreferencesForm = () => {
  const [preferences, setPreferences] = useState([]);
  const [popup, setPopup] = useState({
    show: false,
    description: '',
  });

  useEffect(() => {
    const storedPreferences = JSON.parse(
      localStorage.getItem('impactscore:preferences')
    );

    if (storedPreferences) {
      setPreferences(storedPreferences);
    } else {
      setPreferences([
        { key: 'uk-tax-paid', value: 3, weighting: 1 },
        { key: 'gender-pay-gap', value: 3, weighting: 1 },
        { key: 'real-living-wage', value: 3, weighting: 1 },
        { key: 'co2-emissions', value: 3, weighting: 1 },
        { key: 'pay-suppliers-promptly', value: 3, weighting: 1 },
        { key: 'ceo-pay-ratio', value: 3, weighting: 1 },
      ]);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    const totalPreferences = preferences.reduce((acc, { key }) => {
      return acc + parseInt(e.target[key].value);
    }, 0);

    preferences.map(preference => {
      preference.value = e.target[preference.key].value;
      preference.weighting = (
        (e.target[preference.key].value / totalPreferences) *
        6
      ).toFixed(1);
    });

    setPreferences(preferences);

    localStorage.setItem(
      'impactscore:preferences',
      JSON.stringify(preferences)
    );

    fetch('/.netlify/functions/submitPreferences', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //make sure to serialize your JSON body
      body: JSON.stringify({
        preferences: preferences,
        deviceID: localStorage.getItem('impactscore:id'),
      }),
    })
      .then(response => response.json())
      .then(console.log);

    navigate('/search');
  };

  return (
    <StyledPreferencesForm onSubmit={e => handleSubmit(e)}>
      {preferences.map(({ key, value }) => (
        <StyledPreferencesFormLabel key={key}>
          <StyledPreferencesFormText>
            {getKpiName(key)}
            <StyledPreferencesFormButton
              type="button"
              data-description={getKpiDescription(key)}
              onClick={e =>
                setPopup({
                  show: true,
                  description: e.target.dataset.description,
                })
              }
              pulse={preferences}
            >
              <span>Find out more</span>
            </StyledPreferencesFormButton>
          </StyledPreferencesFormText>
          <StyledPreferencesFormInput
            type="range"
            name={key}
            min="1"
            max="5"
            defaultValue={value}
          />
          <StyledPreferencesFormText alt="true">
            Not important
          </StyledPreferencesFormText>
          <StyledPreferencesFormText alt="true">
            Essential
          </StyledPreferencesFormText>
        </StyledPreferencesFormLabel>
      ))}
      <Button>Update Goals</Button>
      {popup.show && (
        <StyledPreferencesFormPopup>
          <StyledPreferencesFormPopupButton
            onClick={e =>
              setPopup({
                show: false,
                description: '',
              })
            }
          >
            <span>Close</span>
          </StyledPreferencesFormPopupButton>
          <StyledPreferencesFormPopupText>
            {popup.description}
          </StyledPreferencesFormPopupText>
        </StyledPreferencesFormPopup>
      )}
    </StyledPreferencesForm>
  );
};

export default PreferencesForm;
