import React from 'react';
import { Helmet } from 'react-helmet';
import { Heading, Text } from '../components/ui';
import Layout from '../components/Layout';
import PreferencesForm from '../components/PreferencesForm';

const PreferencesPage = () => (
  <Layout isPreferencesPage={true}>
    <Helmet title="Goals | Impact Score" defer={false} />
    <Heading>Goals</Heading>
    <Text>What do you want from the companies you buy from?</Text>
    <PreferencesForm />
  </Layout>
);

export default PreferencesPage;
